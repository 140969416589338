'use client';

import { useMemo, useState } from 'react';
import { Input, Button } from '@nextui-org/react';
import { EyeFilledIcon, EyeSlashFilledIcon } from '@nextui-org/shared-icons';
import { useRouter } from 'next/navigation';
import Header from '@/components/Header';
import { signIn, getSession } from 'next-auth/react';
import useAxiosAuth from 'lib/hooks/useAxiosAuth';
import AccountRepository from 'lib/repositories/AccountRepository';

export default function Login() {
  const router = useRouter();

  const accountRepository = new AccountRepository(useAxiosAuth());
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isVisible, setIsVisible] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const toggleVisibility = () => setIsVisible(!isVisible);
  const validateEmail = (email: string) =>
    email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i);

  const isInvalidEmail = useMemo(() => {
    if (email === '') return false;
    return !validateEmail(email);
  }, [email]);

  async function submitForm(formData: FormData) {
    try {
      const response = await signIn('credentials', {
        email: formData.get('email'),
        password: formData.get('password'),
        redirect: false, // Don't redirect automatically
      });

      if (response && response.ok) {
        // Fetch the session after a successful sign-in
        const session = await getSession();
        if (session) {
          // Store the role data in localStorage
          window.localStorage.removeItem('laboratoryData');
          window.localStorage.setItem(
            'laboratoryData',
            JSON.stringify((session as any).role)
          );
          window.localStorage.removeItem('userData');
          window.localStorage.setItem(
            'userData',
            JSON.stringify((session as any).user)
          );

          // Fetch all attached laboratories
          const laboratoriesData =
            await accountRepository.attachedLaboratories();
          window.localStorage.removeItem('attachedLaboratories');
          // Store the attached laboratories in localStorage
          window.localStorage.setItem(
            'attachedLaboratories',
            JSON.stringify(laboratoriesData)
          );

          // Now, navigate to the home page after login and data storage
          router.push('/');
        }
      } else {
        // Set an error message if login fails
        setError('Email o password errati. Riprova.');
      }
    } catch (err) {
      setError('Si è verificato un errore. Riprova più tardi.');
    }
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        const formData = new FormData(e.target as HTMLFormElement);
        submitForm(formData); // Submit form data
      }}
    >
      <div className="flex w-full items-center justify-between flex-col gap-5">
        <Header title="Login" subtitle="Inserisci email e password" />
        {error && <div className="text-red-500 text-center">{error}</div>}
        <Input
          autoFocus={true}
          isRequired
          isClearable
          id="email"
          name="email"
          type="email"
          label="Email"
          variant="bordered"
          value={email}
          onValueChange={setEmail}
          isInvalid={isInvalidEmail}
          color={isInvalidEmail ? 'danger' : 'primary'}
          errorMessage={isInvalidEmail && "Inserisci un'email valida"}
          className="max-w-xs"
        />
        <Input
          isRequired
          id="password"
          name="password"
          type={isVisible ? 'text' : 'password'}
          label="Password"
          variant="bordered"
          value={password}
          onValueChange={setPassword}
          className="max-w-xs"
          endContent={
            <button
              className="focus:outline-none"
              type="button"
              onClick={toggleVisibility}
            >
              {isVisible ? (
                <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
              ) : (
                <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
              )}
            </button>
          }
        />
        <Button color="primary" type="submit">
          Accedi
        </Button>
      </div>
    </form>
  );
}
