export default function Header({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) {
  return (
    <>
      <div className="text-4xl text-teal-800 font-bold">{title}</div>
      <p className="font-light text-teal-800">{subtitle}</p>
    </>
  );
}
