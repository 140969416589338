const baseUrl = 'auth';

class AccountRepository {
  private axiosAuth;

  constructor(axiosAuth) {
    this.axiosAuth = axiosAuth;
  }

  async changeLaboratory(action: string, teamId: number, roleId: number) {
    const formData = new FormData();
    formData.append('action', 'update_main_team');
    formData.append('team_id', teamId.toString());
    formData.append('role_id', roleId.toString());

    const response = await this.axiosAuth.put('users', formData);

    return response.data;
  }

  async changePassword(newPassword: string) {
    const formData = new FormData();
    formData.append('new_password', newPassword);

    const response = await this.axiosAuth.post(
      `${baseUrl}/change-password`,
      formData
    );

    return response.data;
  }

  async logOut() {
    const response = await this.axiosAuth.post(`${baseUrl}/logout`);

    return response.data;
  }

  async attachedLaboratories() {
    const response = await this.axiosAuth.post(`${baseUrl}/attached-laboratories`);

    return response.data;
  }
}

export default AccountRepository;
